import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"

import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Header from "../../components/header"
import Hero from "../../components/blog/hero"
import Categories from "../../components/blog/categories"
import Recommended from "../../components/blog/recommended"
import SEO from "../../components/seo";


interface PostType {
  title: string,
  publicationDate: string,
  excerpt: string,
  image: any,
  imageCaption: string,
  slug: string,
  category: any,
  fromOffer: boolean
}

export const query = graphql`
    query PostQuery($id: Int!){
        strapiPosts(strapiId: {eq: $id}) {
            title
            publicationDate
            text
            excerpt
            image {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageCaption
            slug
            fromOffer
            category {
                title
                slug
            }
        }
    }
`

const BlogPost = ({data}: any) => {
  const {
    title,
    publicationDate,
    text,
    excerpt,
    image,
    imageCaption,
    slug,
    category
  } = data.strapiPosts

  return (
    <Layout>
      <SEO
        title={title.trim()}
        description={excerpt.trim()}
      />
      <Helmet
        meta={[
          {
            property: `og:url`,
            content: `https://dentandbeauty.pl/${category.slug}/${slug}`
          },
          {
            property: `og:image`,
            content: withPrefix(image.childImageSharp.fluid.src)
          },
        ]}
      >
        <script type={'application/ld+json'}>
        {`
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://dentandbeauty.pl/${category.slug.trim()}/${slug.trim()}"
          },
          "headline": "${title.trim()}",
          "image": "${withPrefix(image.childImageSharp.fluid.src)}",
          "datePublished": "${publicationDate.trim()}",
          "publisher": {
            "name": "dentandbeauty",
            "@type": "Organization",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dentandbeatuy.pl${withPrefix('images/og-image.jpg')}"
            }
          },
          "description": "${excerpt.trim()}",
          "isAccessibleForFree": "True",
          "author": {
            "@type": "Person",
            "name": "dentandbeauty"
          }
        }
        `}
        </script>
      </Helmet>
      <Header />
      <Hero />
      <Categories />
      <Helmet title={`${title} | Centrum Medyczne Dent&Beauty | Świdnica`} />
      <div className="single-blog">
        <div className="row">
          <div className="large-8 medium-7 small-12 columns single-blog-content">
            <div className="top">
              <div className="img">
                <img
                  src={image.childImageSharp.fluid.src}
                  alt={imageCaption}
                />
              </div>
              <div className="excerpt">
                <h1 className="title">
                  {title}
                </h1>
                <div className="post-date">
                  {publicationDate}
                </div>
                <div className="excerpt">
                  {excerpt}
                </div>
              </div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <div className="large-3 medium-4 medium-offset-1 small-12 columns right-sidebar">
            <Recommended />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
