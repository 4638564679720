import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import React from "react"

const Recommended = () => {
  const query = useStaticQuery(graphql`
      query {
          strapiRecommended {
              posts {
                  title
                  category
                  slug
              }
          }
          allStrapiCategories {
              nodes {
                  strapiId
                  slug
              }
          }
      }
  `)

  const posts = query.strapiRecommended.posts

  const recommendedJsx = posts.map((post: any, index: number) => {

    const category = query.allStrapiCategories.nodes.filter((cat: any, index: number) => {
      return cat.strapiId === post.category
    })[0]
    return (
      <li key={index}>
        <Link to={`/${category.slug}/${post.slug}`}>
          {post.title}
        </Link>
      </li>
    )
  })

  return (
    <div className="blog-recent-posts">
      <span>Polecane</span>
      <ul>
        {recommendedJsx}
      </ul>
    </div>
  )
}

export default Recommended



